import React from "react"
import Layout from "../components/layout/Layout.js"
import { Column100 } from "../components/column/Column.js"
import { BlogRow, Row } from "../components/row/Row.js"
import { TextMedium, TextBig } from "../components/text/Text"
import { BlogTitle, Title } from "../components/title/Title"
import { Section } from "../components/section/Section.js"
import Seo from "../components/seo"


function Privacy () {
  return (
  <Layout>
    <Seo title="Privacy Policy" />
    <Section>
      <BlogRow isCentered>
        <BlogTitle isPrimary title="Privacy Policy" />
        <TextMedium
          textMedium="This Privacy Policy covers our data collection practices and describes 
        your rights to access, adjust or limit our use of your Personal Information. This document refers to this Website,
         our learning materials and related Services. By using our Services, you agree to the terms of this Privacy 
         Policy."
        />
        <TextBig textBig="Data we collect" />
        <TextMedium
          textMedium="We collect data about you when you fill in our forms to contact us, subscribe to our newsletter, 
          or register for our courses. This may include information such as your contact details, company or job title, 
          preferences and interests, project description and so on."
        />
        <TextMedium
          textMedium="When you buy Products through our Website, we collect information about your 
          purchase, such as your contact details and e-mail address, which are necessary for processing your order. We 
          do not collect information about your credit card or other payment methods, for security reasons. This data is handled 
          through third-party Services."
        />
        <TextMedium
          textMedium="If you are connected to our social media accounts, or land on our Website by interacting with our social 
          media posts, we may process and collect some of your data, such as your demographic details, interests, or 
          browsing history. This is done through third-party Services, as described in our Cookie Policy. You can always 
          adjust your preferences in the Privacy settings of your account, for each social media platform."
        />
        <TextMedium
          textMedium="Some data is collected automatically, for example information about your device, 
        the pages that you interact with and your browsing history, or demographic information via third-party 
        services. We may collect information about your IP address, operating system, your behavior on our Website - pages 
        visited and time spent on them, click data, search queries."
        />
        <TextBig textBig="Data collection methods and use" />
        <TextMedium
          textMedium="We collect data through web forms, cookies, analytics services and third-party services. You can 
        always opt out of data collection by following the guidelines offered by the third-party services, or in the case of this Website, 
        by directly contacting us."
        />
        <TextMedium
          textMedium="We process and collect your data when your register for our courses, buy our products or 
        fill in our web forms. Also, we collect data when you participate in our industry research surveys, when you engage with 
        our posts in social media, or when you participate to our offline events. As per our Cookie Policy, we 
        collect data through third-party services like Google Analytics, Google Tag Manager, HubSpot and Hotjar. 
        This information is collected automatically, through cookies, scripts, tags and custom links."
        />
        <TextMedium
          textMedium="We use your data to communicate with you, provide you with the requested Services or Products, 
        as well as improve our Services and Website based on your online behavior. We may use your data to display 
        personalized content, such as written content, banners or recommendations for Services and Products. 
        We use your data to send you e-mails, newsletters or surveys, if you gave your consent for such 
        communication."
        />
        <TextMedium
          textMedium="Data collected through third-party services may be used to understand your online 
        behavior and preferences, analyze traffic and trends, or tailor advertisments on this Website or on 
        third-party platforms. We may share certain data about you with our instructors or with 
        companies performing services for us, such as payment processors, advertising companies, or business partners 
        in case of events. We may disclose your data to third parties for security and legal compliance, in accordance 
        with the law of the countries we operate in - The Netherlands and Romania."
        />
        <TextMedium
          textMedium="You can opt out of data collection at any moment by contacting us or the third parties mentioned 
        in our Privacy and Cookie Policy pages. If you don't opt out, we may retain your Personal Information - the data 
        collected - for as long as is necessary to provide you with the Services and Products you've requested through 
        this Website, our social media platforms or at offline events."
        />
        <TextMedium
          textMedium="We take all the necessary security measures to protect against unauthorized access, 
        alteration, disclosure, or destruction of your data. However, no system is 100% secure, so we cannot guarantee 
        that the information you provide to us will be free from unauthorized access. We are not responsible for 
        the safety and security of the third-party Services we work with. If you believe your data or passwords have been 
        compromised, change them immediately or contact the Service providers involved."
        />
        <TextBig textBig="Your rights in respect of your Personal Information" />
        <TextMedium
          textMedium="You have the right to request access to the information that we collect about you, including 
        the purposes for which we are processing your information. You can request that your information
        be adjusted if it's incorrect, by contacting us at contact@schoolofcontent.net. We will comply with the 
        requirements of data protection law when processing your request, and reply within four weeks. You have the right
         to ask us not to process your personal data, stop using it for marketing and advertising purposes, or delete it from our 
         records. You have the right of data portability, and the right to withdraw your given consent at any time. 
         If you're a citizen of the European Union, you can read more about your data protection rights under GDPR
          by referring to the Supervisory Authority or national legislation of your State."
        />
        <TextMedium
          textMedium="You can choose to not provide certain data to us, but that might mean that we won't 
        be able to provide you with our Services or Products. If you wish to opt out from receiving our promotional 
        communication, you can do so by using the unsubscribe link in the communication your receive. In case of transactional 
        and relationship messages regarding our Services or Products, such as order confirmation e-mails, registration confirmation 
        messages or important updates about our Services or Products, we will contact you regardless of your preferences. 
        If you wish you suspend all communications from our side, please contact us at contact@schoolofcontent.net. When contacting us, 
        please provide sufficient information to allow us to identify your data in our records. We will process your request 
        and get in touch within four weeks."
        />
        <TextMedium
          textMedium="Please be aware that cookies and other personal information may be stored on other devices 
        that you are using, such as mobile phone or tablet. To control the way this information is processed, please visit Your 
        Online Choices. To opt out of Google’s display advertising or customize Google Display Network ads, please visit 
        the Google Ads Settings page. To opt out of allowing Google Analytics to use your data for analytics or
         enrichment, see the Google Analytics Opt-out Browser Add-on. If you have any questions about your
        data, our use of it, or your rights, contact us at contact@schoolofcontent.net."
        />
        <TextMedium
          textMedium="If you have opted out from data collection, some of your data might still be visible to others, 
        including without limitation any information that has been copied, stored or disseminated by other parties, shared by you 
        or others, collected or posted on third-party platforms. We retain your data for as long as we have a legitimate
         purpose to do so, in accordance with applicable law, including to assist with legal obligations. If you have requested 
         to adjust your data, we may  require that your request be sent through the e-mail address associated with your account, 
         and we may ask you to verify your identity before implementing your request."
        />
        <TextBig textBig="Updates to this Privacy Policy" />
        <TextMedium
          textMedium="We will notify all our users when making changes to this Privacy Policy, via e-mail or 
        as required by law. In our communication, we will include a summary of the key changes. Changes become 
        effective the day they are posted. After the effective date of any change, the updated Privacy Policy supersedes 
        all previous versions of this document."
        />
        <TextMedium
          textMedium="If you have questions or concerns, feel free to contact us at contact@schoolofcontent.net. 
        Address: School of Content, Spoorstraat 28, 1815BK, Alkmaar, The Netherlands."
        />
      </BlogRow>
    </Section>
  </Layout>
)
}

export default Privacy



// export default ({ children }) => (
//   <Layout>
//     <SEO title="Privacy Policy" />
//     <Row isCentered>
//       <Column100>
//         <Title title="Privacy Policy" />
//         <TextMedium
//           textMedium="This Privacy Policy covers our data collection practices and describes 
//         your rights to access, adjust or limit our use of your Personal Information. This document refers to this Website,
//          our learning materials and related Services. By using our Services, you agree to the terms of this Privacy 
//          Policy."
//         />
//         <TextBig textBig="Data we collect" />
//         <TextMedium
//           textMedium="We collect data about you when you fill in our forms to contact us, subscribe to our newsletter, 
//           or register for our courses. This may include information such as your contact details, company or job title, 
//           preferences and interests, project description and so on."
//         />
//         <TextMedium
//           textMedium="When you buy Products through our Website, we collect information about your 
//           purchase, such as your contact details and e-mail address, which are necessary for processing your order. We 
//           do not collect information about your credit card or other payment methods, for security reasons. This data is handled 
//           through third-party Services."
//         />
//         <TextMedium
//           textMedium="If you are connected to our social media accounts, or land on our Website by interacting with our social 
//           media posts, we may process and collect some of your data, such as your demographic details, interests, or 
//           browsing history. This is done through third-party Services, as described in our Cookie Policy. You can always 
//           adjust your preferences in the Privacy settings of your account, for each social media platform."
//         />
//         <TextMedium
//           textMedium="Some data is collected automatically, for example information about your device, 
//         the pages that you interact with and your browsing history, or demographic information via third-party 
//         services. We may collect information about your IP address, operating system, your behavior on our Website - pages 
//         visited and time spent on them, click data, search queries."
//         />
//         <TextBig textBig="Data collection methods and use" />
//         <TextMedium
//           textMedium="We collect data through web forms, cookies, analytics services and third-party services. You can 
//         always opt out of data collection by following the guidelines offered by the third-party services, or in the case of this Website, 
//         by directly contacting us."
//         />
//         <TextMedium
//           textMedium="We process and collect your data when your register for our courses, buy our products or 
//         fill in our web forms. Also, we collect data when you participate in our industry research surveys, when you engage with 
//         our posts in social media, or when you participate to our offline events. As per our Cookie Policy, we 
//         collect data through third-party services like Google Analytics, Google Tag Manager, HubSpot and Hotjar. 
//         This information is collected automatically, through cookies, scripts, tags and custom links."
//         />
//         <TextMedium
//           textMedium="We use your data to communicate with you, provide you with the requested Services or Products, 
//         as well as improve our Services and Website based on your online behavior. We may use your data to display 
//         personalized content, such as written content, banners or recommendations for Services and Products. 
//         We use your data to send you e-mails, newsletters or surveys, if you gave your consent for such 
//         communication."
//         />
//         <TextMedium
//           textMedium="Data collected through third-party services may be used to understand your online 
//         behavior and preferences, analyze traffic and trends, or tailor advertisments on this Website or on 
//         third-party platforms. We may share certain data about you with our instructors or with 
//         companies performing services for us, such as payment processors, advertising companies, or business partners 
//         in case of events. We may disclose your data to third parties for security and legal compliance, in accordance 
//         with the law of the countries we operate in - The Netherlands and Romania."
//         />
//         <TextMedium
//           textMedium="You can opt out of data collection at any moment by contacting us or the third parties mentioned 
//         in our Privacy and Cookie Policy pages. If you don't opt out, we may retain your Personal Information - the data 
//         collected - for as long as is necessary to provide you with the Services and Products you've requested through 
//         this Website, our social media platforms or at offline events."
//         />
//         <TextMedium
//           textMedium="We take all the necessary security measures to protect against unauthorized access, 
//         alteration, disclosure, or destruction of your data. However, no system is 100% secure, so we cannot guarantee 
//         that the information you provide to us will be free from unauthorized access. We are not responsible for 
//         the safety and security of the third-party Services we work with. If you believe your data or passwords have been 
//         compromised, change them immediately or contact the Service providers involved."
//         />
//         <TextBig textBig="Your rights in respect of your Personal Information" />
//         <TextMedium
//           textMedium="You have the right to request access to the information that we collect about you, including 
//         the purposes for which we are processing your information. You can request that your information
//         be adjusted if it's incorrect, by contacting us at contact@schoolofcontent.net. We will comply with the 
//         requirements of data protection law when processing your request, and reply within four weeks. You have the right
//          to ask us not to process your personal data, stop using it for marketing and advertising purposes, or delete it from our 
//          records. You have the right of data portability, and the right to withdraw your given consent at any time. 
//          If you're a citizen of the European Union, you can read more about your data protection rights under GDPR
//           by referring to the Supervisory Authority or national legislation of your State."
//         />
//         <TextMedium
//           textMedium="You can choose to not provide certain data to us, but that might mean that we won't 
//         be able to provide you with our Services or Products. If you wish to opt out from receiving our promotional 
//         communication, you can do so by using the unsubscribe link in the communication your receive. In case of transactional 
//         and relationship messages regarding our Services or Products, such as order confirmation e-mails, registration confirmation 
//         messages or important updates about our Services or Products, we will contact you regardless of your preferences. 
//         If you wish you suspend all communications from our side, please contact us at contact@schoolofcontent.net. When contacting us, 
//         please provide sufficient information to allow us to identify your data in our records. We will process your request 
//         and get in touch within four weeks."
//         />
//         <TextMedium
//           textMedium="Please be aware that cookies and other personal information may be stored on other devices 
//         that you are using, such as mobile phone or tablet. To control the way this information is processed, please visit Your 
//         Online Choices. To opt out of Google’s display advertising or customize Google Display Network ads, please visit 
//         the Google Ads Settings page. To opt out of allowing Google Analytics to use your data for analytics or
//          enrichment, see the Google Analytics Opt-out Browser Add-on. If you have any questions about your
//         data, our use of it, or your rights, contact us at contact@schoolofcontent.net."
//         />
//         <TextMedium
//           textMedium="If you have opted out from data collection, some of your data might still be visible to others, 
//         including without limitation any information that has been copied, stored or disseminated by other parties, shared by you 
//         or others, collected or posted on third-party platforms. We retain your data for as long as we have a legitimate
//          purpose to do so, in accordance with applicable law, including to assist with legal obligations. If you have requested 
//          to adjust your data, we may  require that your request be sent through the e-mail address associated with your account, 
//          and we may ask you to verify your identity before implementing your request."
//         />
//         <TextBig textBig="Updates to this Privacy Policy" />
//         <TextMedium
//           textMedium="We will notify all our users when making changes to this Privacy Policy, via e-mail or 
//         as required by law. In our communication, we will include a summary of the key changes. Changes become 
//         effective the day they are posted. After the effective date of any change, the updated Privacy Policy supersedes 
//         all previous versions of this document."
//         />
//         <TextMedium
//           textMedium="If you have questions or concerns, feel free to contact us at contact@schoolofcontent.net. 
//         Address: School of Content, Spoorstraat 28, 1815BK, Alkmaar, The Netherlands."
//         />
//       </Column100>
//     </Row>
//   </Layout>
// )
